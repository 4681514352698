import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LEGAL_VALUE_TYPE_HTML, LEGAL_VALUE_TYPE_LINK, LEGAL_VALUES_TYPES } from '../tenant-legal-form.constants';

@Component({
  selector: 'app-edit-tenant-legal-dialog',
  templateUrl: './edit-tenant-legal-dialog.component.html'
})
export class EditTenantLegalDialogComponent {

  public valueTypes = LEGAL_VALUES_TYPES;
  public LEGAL_VALUE_TYPE_LINK = LEGAL_VALUE_TYPE_LINK;
  public LEGAL_VALUE_TYPE_HTML = LEGAL_VALUE_TYPE_HTML;

  public legalUnit: any;
  public label: string;
  public editMode = true;
  public editor: Object = {
    theme: 'eclipse',
    mode: 'html'
  };

  constructor(
    public dialogRef: MatDialogRef<EditTenantLegalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.legalUnit = data.legalUnit;
    this.label = data.label;
  }

  onChange(val): void {
    this.legalUnit.value = val;
  }

  public setEditMode(state: boolean): void {
    this.editMode = state;
  }

  public updateLegals(): void {
    this.dialogRef.close(this.legalUnit);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

}
