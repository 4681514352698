import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { APP_CONSTANTS } from '../../app.constants';
import * as config from '../../../config/config.json';

@Component({
  selector: 'app-main-bar',
  templateUrl: './main-bar.component.html'
})
export class MainBarComponent implements OnInit {

  public title = APP_CONSTANTS.NAME;
  public userName = '';
  public accountUrl = `${config.auth.url}/realms/${config.auth.realm}/account`;

  constructor(private readonly keycloak: KeycloakService) { }

  public setUserName() {
    this.userName = this.keycloak.getUsername();
  }

  public logOut() {
    this.keycloak.logout();
  }

  ngOnInit() {
    this.setUserName();
  }
}
