import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSelectChange, MatTableDataSource } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { ITenant, IThemeListEntry } from './interfaces/tenant.interface';
import { TenantService } from './service/tenant.service';
import { SnackbarService } from '../shared/service/snackbar.service';
import { DeleteTenantDialogComponent } from './delete-tenant-dialog/delete-tenant-dialog';
import { AddTenantDialogComponent } from './add-tenant-dialog/add-tenant-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css']
})
export class TenantsComponent implements OnInit {
  public loading = true;
  public error = false;
  public hasData = false;
  public isDefaultSet = false;
  public total = 0;
  public versions: string[] = [];
  public tenantItems: ITenant[] = [];
  public themesItems: IThemeListEntry[] = [];
  public tenants: MatTableDataSource<ITenant>;
  public displayedColumns: Array<string> = ['id', 'theme', 'defaultLocale', 'action'];
  public themeFormGroup: FormGroup;
  public selectedThemeVersion: string;

  constructor(
    private readonly tenantService: TenantService,
    private readonly snackbarService: SnackbarService,
    private readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly titleService: Title
  ) { }

  changeTheme(event: MatSelectChange) {
    this.selectedThemeVersion = event.value;
  }

  setThemeVersionForAllTenants(): void {
    this.tenantItems.forEach(tenant => {
      const matchingThemeListEntry = this.themesItems.find(theme => theme.id === tenant.id);
      const matchingThemeVersion = matchingThemeListEntry && matchingThemeListEntry.versions.find(e => e === this.selectedThemeVersion);

      // some tenants have old versions, first check if the selected version is available for this tenant
      if (matchingThemeVersion) {
        tenant.theme.version = this.selectedThemeVersion;
        this.tenantService.updateTenant(tenant).subscribe();
      }
    });
  }

  private checkIfDefaultIsSet(items: ITenant[]): boolean {
    return items.some((el) => el.id.toLowerCase() === 'default');
  }

  private setTenants(): void {
    forkJoin([
      this.tenantService.getThemes(),
      this.tenantService.getTenants()
    ]).subscribe(([themesData, tenantsData]) => {
      const kiwiId = 'kiwigrid';
      this.tenantItems = tenantsData.items;
      this.themesItems = themesData.items;

      this.versions = this.themesItems.find(item => item.id === kiwiId).versions;
      this.selectedThemeVersion = this.tenantItems.map(item => item.theme).find(theme => theme.id === kiwiId).version;

      this.themeFormGroup = this.formBuilder.group({
        version: [this.selectedThemeVersion, Validators.required],
      });

      this.tenants = new MatTableDataSource(tenantsData.items);
      this.total = tenantsData.total;
      this.isDefaultSet = this.checkIfDefaultIsSet(tenantsData.items);
      this.loading = false;
      this.error = false;
      this.hasData = tenantsData.items && tenantsData.items.length > 0;
    });
  }

  public filterTenants(filter: string): void {
    this.tenants.filter = filter.trim().toLowerCase();
  }

  private tenantAlreadyExists(tenantId: string): boolean {
    return this.tenants && this.tenants.data && this.tenants.data.some(t => t.id === tenantId);
  }

  public openAddDialog(): void {
    const dialogRef = this.dialog.open(AddTenantDialogComponent, { data: { isDefaultSet: this.isDefaultSet } });
    dialogRef.afterClosed().subscribe(tenantId => {
      if (tenantId) {
        if (this.tenantAlreadyExists(tenantId)) {
          this.snackbarService.open(`${tenantId} already exists`);
        } else {
          this.loading = true;
          const tenant = { ...this.tenantService.getTenantExample(), ...{ id: tenantId } };
          this.tenantService.addTenant(tenant).subscribe(() => {
            this.snackbarService.open(`${tenant} was added`);
            this.setTenants();
          }, () => {
            this.snackbarService.open(`${tenant} cannot be added`);
          });
        }
      }
    });
  }

  public openDeleteDialog(tenant: ITenant): void {
    const dialogRef = this.dialog.open(DeleteTenantDialogComponent, { data: { tenant } });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.tenantService.deleteTenant(result).subscribe(() => {
          this.setTenants();
          this.snackbarService.open(`${result.id} was deleted`);
        }, () => {
          this.snackbarService.open(`${result.id} cannot be deleted`);
        });
      }
    });
  }

  ngOnInit(): void {
    this.setTenants();
    this.titleService.setTitle('Tenant List');
  }

}
