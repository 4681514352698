import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LEGAL_VALUE_TYPE_HTML, LEGAL_VALUE_TYPE_LINK, LEGAL_VALUES_TYPES } from '../tenant-legal-form.constants';
import { ILegalItem } from '../../../interfaces/tenant.interface';
import { TenantLegalUnitExample } from '../../../service/tenant.examples';
import { saveCopyObject } from '../../../../shared/helper/deep-merge.helper';

@Component({
  selector: 'app-add-tenant-legal-dialog',
  templateUrl: './add-tenant-legal-dialog.component.html'
})
export class AddTenantLegalDialogComponent {

  public valueTypes = LEGAL_VALUES_TYPES;
  public selectedValueType = LEGAL_VALUE_TYPE_LINK;
  public LEGAL_VALUE_TYPE_LINK = LEGAL_VALUE_TYPE_LINK;
  public LEGAL_VALUE_TYPE_HTML = LEGAL_VALUE_TYPE_HTML;

  public legalUnit: ILegalItem = saveCopyObject(TenantLegalUnitExample);
  public label: string;
  public selectableLocales: string[];
  public editMode = true;
  public editor: Object = {
    theme: 'eclipse',
    mode: 'html'
  };

  constructor(
    public dialogRef: MatDialogRef<AddTenantLegalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.label = data.label;
    this.selectableLocales = data.selectableLocales;
    this.legalUnit.locale = data.selectableLocales[0];
  }

  onChange(val): void {
    this.legalUnit.value = val;
  }

  public setEditMode(state: boolean): void {
    this.editMode = state;
  }

  public addLegal(): void {
    this.dialogRef.close(this.legalUnit);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

}
