import { Component } from '@angular/core';
import { APP_CONSTANTS } from '../../app.constants';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html'
})
export class FooterBarComponent {
  public appName = APP_CONSTANTS.NAME;
  public appVersion = APP_CONSTANTS.VERSION;
}
